export const players = [
  {
    id: 1,
    name: "Idricealy MOURTADHOI",
    age: "22 yo, 29/04/2001",
    placeOfBirth: "Marseille, France",
    codingStrengths: "Kotlin, Vue.js",
    codingWeakness: "IA",
    biggestAchievement: "Hackaton 2022 (dedicated to FPF)",
    interesting: "Football",
    image1: "idrice1",
    image2: "idrice2",
  },
  {
    id: 2,
    name: "Chevenslove EDOUARD",
    age: "25 yo, 06/02/1998",
    placeOfBirth: "Leogane, Haiti",
    codingStrengths: "JS, React",
    codingWeakness: "IA",
    biggestAchievement:
      "A Monitoring and Evaluation Platform for a consortium (ACF, HEIFER, JWT)",
    interesting: "Football and Music",
    image1: "love1",
    image2: "love2", // Replace with actual image paths
  },
  {
    id: 3,
    name: "Pierre-Louis SQUAGLIA",
    age: "22 yo, 14/11/2001",
    placeOfBirth: "Bastia, Corse",
    codingStrengths: "JS, Angular",
    codingWeakness: "IA",
    biggestAchievement: "No Code",
    interesting: "Central Back in Football",
    image1: "pilou1",
    image2: "pilou2", // Replace with actual image paths
  },
  {
    id: 4,
    name: "Toussaint CARLOTTI",
    age: "23 yo, 17/12/2000",
    placeOfBirth: "Ajaccio, Corse",
    codingStrengths: "Laravel, VueJs",
    codingWeakness: "Web developer",
    biggestAchievement:
      "Creation of an application to manage a company's quotes and customers",
    interesting: "FootBall, Rhum",
    image1: "toussaint1",
    image2: "toussaint2",
  },
  {
    id: 5,
    name: "Dimitri Denis",
    age: "25, 21,05,1998",
    placeOfBirth: "Tours, France",
    codingStrengths: "Angular",
    codingWeakness: "Everything else",
    biggestAchievement: "Quoted in Corse-matin but not for coding",
    interesting: "FootBall, culture and money",
    image1: "dimitri1",
    image2: "dimitri2",
  },
  {
    id: 6,
    name: "Amine MAOURID",
    age: "27 yo, 23/05/1996",
    placeOfBirth: "Casablanca, Morocco",
    codingStrengths: "Laravel, React",
    codingWeakness: "IA shit and data science",
    biggestAchievement: "An app that serves as a mini social network...",
    interesting: "FootBall, Chess",
    image1: "amine1",
    image2: "amine2",
  },
  {
    id: 7,
    name: "Félicien BERTRAND",
    age: "22 yo, 14/07/2001",
    placeOfBirth: "Bastia, Corsica",
    codingStrengths: "Python, database",
    codingWeakness: "IA",
    biggestAchievement: "PIX Challenge Innovation 2023",
    interesting: "All sports, video games",
    image1: "felicien1",
    image2: "felicien2",
  },
  {
    id: 8,
    name: "Jean BERTRAND",
    age: "22 yo, 14/07/2001",
    placeOfBirth: "Bastia, Corsica",
    codingStrengths: "Python, web",
    codingWeakness: "iOS",
    biggestAchievement: "Hackathon 2022 (automatic content generation)",
    interesting: "I'm blue right now",
    image1: "jean1",
    image2: "jean2",
  },
  {
    id: 9,
    name: "Tom OLIVIER",
    age: "24 yo ,02/02/1999",
    placeOfBirth: "Paris, France",
    codingStrengths: "Python, database, web",
    codingWeakness: "Network",
    biggestAchievement:
      "Entrop 'var, Hackaton 2023 and 2024, Challenge innovation, Pix, Voltaire",
    interesting: "Triathlon, street workout",
    image1: "tom1",
    image2: "tom2",
  },
  {
    id: 10,
    name: "Fode Doumbouya",
    age: "24 yo, 09/12/1999",
    placeOfBirth: "Conakry, Guinea",
    codingStrengths: "C++, React Native",
    codingWeakness: "Cloud computing",
    biggestAchievement:
      "Developing a blockchain-based solution for supply chain management",
    interesting: "Basketball, Traveling",
    image1: "fode1",
    image2: "fode2",
  },
  {
    id: 11,
    name: "Jean KHOGE",
    age: "24 yo, 28/07/1999",
    placeOfBirth: "Afrine, Syrie",
    codingStrengths: "Python, Django",
    codingWeakness: "Data Science",
    biggestAchievement: "Developing a custom CRM system for a local NGO",
    interesting: "Hiking, Photography",
    image1: "jeank1",
    image2: "jeank2",
  },
  {
    id: 12,
    name: "Francois FOCARDI",
    age: "22 yo, 15/03/2001",
    placeOfBirth: "Bastia, France",
    codingStrengths: "Java, Spring",
    codingWeakness: "UI/UX Design",
    biggestAchievement:
      "Developing a scalable microservices architecture for a fintech startup",
    interesting: "Gardening, Cooking",
    image1: "francois1",
    image2: "francois2",
  },
  {
    id: 13,
    name: "Jean Jose",
    age: "22 yo, 15/09/2001",
    placeOfBirth: "Corsica, France",
    codingStrengths: "JavaScript, Node.js",
    codingWeakness: "UI/UX Design",
    biggestAchievement:
      "Developing a real-time chat application with Socket.io",
    interesting: "Music, Cooking",
    image1: "jj1",
    image2: "jj2",
  },
  {
    id: 14,
    name: "Quentin Poto",
    age: "27 yo, 15/03/1996",
    placeOfBirth: "Aleria, France",
    codingStrengths: "JavaScript, Node.js",
    codingWeakness: "Machine Learning",
    biggestAchievement: "Developing a secure messaging application",
    interesting: "Reading, Cooking",
    image1: "quentin1",
    image2: "quentin2",
  },
  {
    id: 15,
    name: "Marc Williams",
    age: "Unknown",
    placeOfBirth: "London, United Kingdom",
    codingStrengths: "Unknown",
    codingWeakness: "Unknown",
    biggestAchievement:
      "Improving English literacy rates in underprivileged areas",
    interesting: "Teaching English, Reading",
    image1: "teacher1",
    image2: "teacher2",
  },
];
